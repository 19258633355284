<template>
  <div style="margin:10px 0 10px 10px">
    <el-input placeholder="公司名" v-model="listQuery.name" style="width:120px"></el-input>
    <el-date-picker
      v-model="this.listQuery.dqsj"
      type="date"
      size="mini"
      placeholder="账套到期日期"
      style="width:140px;margin-left: 10px;"
      @change="getList"
      value-format="YYYY-MM-DD">
    </el-date-picker>
    <selectAddress v-model:address="listQuery.address" @success="getList()" style="margin-left:10px"></selectAddress>
    <el-button type="primary" @click="getList">搜索</el-button>
  </div>
<div>
  <el-table size="mini" v-loading="isLoading" :data="tableData"  border style="width: 100%" :height="contentStyleObj" :header-cell-style="{background:'#66b1ff',color:'#ffff'}" @selection-change="handleSelectionChange">
      <el-table-column fixed type="index" width="50" label="序号" align="center"  ></el-table-column>
      <el-table-column label="企业名称" align="center" min-width="190">
        <template #default="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="账套个数" align="center">
       <template #default="scope">
        {{scope.row.count}}
       </template>
      </el-table-column>
      <el-table-column label="状态" align="center" min-width="90">
        <template #default="scope">
          <div v-if="scope.row.isTrial != 1">
            <span v-if="scope.row.status == 1" style="color:green">正常使用</span>
            <span v-else style="color:red">已停用</span>
          </div>
          <div v-else>
            <span v-if="scope.row.status == 1" style="color:blue">试用</span>
            <span v-else style="color:red">已停用</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="账套到期日期" align="center" width="120">
        <template #default="scope">
            <span>{{scope.row.countUseTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="负责人" align="center" width="130">
        <template #default="scope">
          <span>{{scope.row.manager}}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号" align="center">
       <template #default="scope">
        {{scope.row.managerTel}}
       </template>
      </el-table-column>
      <el-table-column label="地区" align="center" min-width="90px">
				<template #default="scope">
					{{ $cityFilter(scope.row.addresspy)}}
				</template>
			</el-table-column>
      <el-table-column label="地址" align="center" min-width="370">
       <template #default="scope">
        {{scope.row.address}}
       </template>
      </el-table-column>
    </el-table>
</div>
</template>

<script>
import selectAddress from '@/components/select/selectAddress';

import { getCompanyList  } from "@/api/taxBureau";
export default {
  components: {
		selectAddress,
	},
  data() {
    return {
      listQuery:{
        page:1,
        limit:20
      },
      total: 0,
      tableData: [],
      multipleSelection:[],
      dialogVisible:false,
      dialogVisibleUpdate:false,
      dialogFormVisibleImport:false,
      visible:false,
      isLoading:false,
      form:{
        name:'',
        loginType:'',
        taxNo:'',
        idNo:'',
        password:'',
        discode:''
      },
      updateForm:{
        name:'',
        loginType:'',
        taxNo:'',
        idNo:'',
        password:'',
        discode:''
      },
      value:[],
      options:[],
      loginTypeOptions:[
        {
          name:'申报密码登录',
        },
        {
          name:'实名信息登录',
        },
      ],
      path:'',
      rules: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        loginType: [
          { required: true, message: '请输入登录方式', trigger: 'blur' },
        ],
        taxNo: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
        ],
        idNo: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        discode: [
          { required: true, message: '请选择地区', trigger: 'blur' },
        ],
      },
      contentStyleObj:{},

    }
  },
  created () {
		this.contentStyleObj = this.$getHeight(280)
    this.getList()
    // this.init()
  },
  methods: {
    // 获取list
    getList(){
      this.isLoading = true
      getCompanyList(this.listQuery).then(res=>{
        this.isLoading = false
        this.total = res.data.data.total
        if(res.data.data.list){
          this.tableData = res.data.data.list
        }else{
          this.tableData = []
        }

      })
    },
    init(){
      disCode({}).then(res => {
        if(res.data.msg == "success"){
          this.options = res.data.data.list
        }
      })
    },
    // 选中条数 
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    // 打开
    handleOpen(){
     this.dialogVisible = true
     this.form = {}
    },
    // 导入
    handleImport(){
      this.dialogFormVisibleImport = true
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = rawFile.name; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile
    },
    uploadExcel(){
      this.importLoading = true
      const param = new FormData() // 创建form对象
      param.append('file', this.rawFile, this.rawFile.name)
      importExcel(param).then(res => {
        this.importLoading = false
        console.log(res.data.data.data[0].data);
        let header = res.data.data.data[0].data.slice(1)
        let items = []
        header.map(v => {
          console.log(v);
          let obj = {}
          obj.name = v[0]
          obj.loginType = v[1]
          obj.taxNo = v[2]
          obj.idNo = v[3]
          obj.password = v[4]
          obj.discode = v[5]
          console.log(obj);
          items.push(obj)
        })
        let list = []
        items.map(v => {
          list.unshift({
            name : v.name.toString(),
            loginType : v.loginType.toString(),
            taxNo : v.taxNo.toString(),
            idNo : v.idNo.toString(),
            password : v.password.toString(),
            discode : v.discode.toString()
          })
        })
        // console.log(list);
        companySave(list).then(res => {
          this.dialogFormVisibleImport = false
          if(res.data.msg == "success"){
              this.dialogVisible = false
              this.$message({
                message: '导入成功',
                type: 'success'
              });
              this.getList()
            }
        })
      })
    },
    // 新增
    handleCreate(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          companySave([this.form]).then(res => {
            if(res.data.msg == "success"){
              this.dialogVisible = false
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.getList()
            }
          })
        } else {
          return false;
        }
      });
    },
    // 编辑
    handleUpdate(row){
      this.updateForm = row
      this.dialogVisibleUpdate = true
    },
    // 编辑确认
    handleUpdateSure(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          companySave([this.updateForm]).then(res => {
            if(res.data.msg == "success"){
              this.dialogVisibleUpdate = false
              this.$message({
                message: '编辑成功',
                type: 'success'
              });
              this.getList()
            }
          })
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 删除
    handleDelete(row){
      companyDel([{id:row.id}]).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '删除',
            type: 'success'
          });
          this.getList()
        }
      })
    },
    // 批量删除
    handleDeleteAll(){
      companyDel(this.multipleSelection).then(res=>{
        if(res.data.msg == "success"){
          this.$message({
            message: '删除',
            type: 'success'
          });
          this.getList()
        }
      })
    },
    // 选择地区
    handleChange(value){
      console.log(value);
    } 
  },
  activated () {
    
  },
}
</script>
<style lang="scss">
.shubiao{
 cursor: pointer; 
}
</style>
<style lang="scss" scoped>
 
.home{
  padding: 10px;
  .header{
    margin-bottom: 10px;
    .right{
      float: right;
    }
    .input-search{
      width: 180px;
      margin-right: 5px;
    }
  }
  .img-ewm{
    width: 50px;
  }
}
.pagination{
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
.el-input {
  width: 80%;
}
</style>